import React from 'react'
import PaddedLayout from '../Components/PaddedLayout'

export default function Updates() {
  return (
    <PaddedLayout>
      <div className="updates-page">
      <h1>Updates</h1>
      <h3>Recent Updates</h3>
          <ul>
            <li>Search Functionality Mobile</li>
            <li>Search Functionality Mobile</li>
            <li>Global Variables CSS</li>
            <li>Updated Contact Page</li>
            <li>Added Updates Page</li>
          </ul>
          <h3>Upcoming Updates</h3>
          <ul>
            <li>Checkout System </li>
            <li>Desktop Functionality </li>
          </ul>
      </div>

    </PaddedLayout>
  )
}
