import React, { useState } from 'react'
import { useAuth } from '../../AuthContext'
import { FaRegTrashAlt } from 'react-icons/fa'
// Style this in navigation.scss, which the parent is "dropdownmenu > items"

export default function CartItem() {
    const { currentUser, cartItems, cartTotal, setCartTotal } = useAuth()
    return (
        <div>
            {cartItems.map((item, index) => (
            <div className="items" id={index}>
            <div><img src={item.image} /></div>
                <div> 
                    <ul>
                    <li className="title">{item.name}</li>
                    <li className="cart-category">{item.category}</li>
                    <li className="cart-quantity">Qty: {item.quantity}</li>
                    </ul>
                </div>
                <div>${item.price}</div>
                <div><FaRegTrashAlt /></div>
        </div>
            ))}

        </div>
    )
}
