import React from 'react'
import PaddedLayout from '../Components/PaddedLayout'

export default function Terms() {
    return (
        <PaddedLayout>
            <h1>
                Terms of Use
            </h1>
        </PaddedLayout>
    )
}
