import React from 'react'
import Layout from '../Components/Layout'

export default function About() {
    return (
        <Layout>
            
        </Layout>
    )
}
