// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBmEbWPbfKQofN3usED7M6GF0X2crSwZc0",
  authDomain: "rps-parts-site.firebaseapp.com",
  projectId: "rps-parts-site",
  storageBucket: "rps-parts-site.appspot.com",
  messagingSenderId: "872041861366",
  appId: "1:872041861366:web:a53a371c5ffa4346fbdcd7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);