import React from 'react'
import Layout from '../Components/Layout'

export default function MyEquipment() {
    return (
        <Layout>
            <div className="padded-wrapper">
                <h1>My Equipment</h1>
            </div>
        </Layout>
    )
}
