import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { MdClose } from 'react-icons/md'
import { FcGoogle } from 'react-icons/fc'
import { useAuth } from '../AuthContext'
export default function Login() {
    const { showLogin, setShowLogin, googleSignin } = useAuth();
    const [disabled, setDisiabled] = useState(false)

    const closeLogin = () => {
        setShowLogin(false)
    }
    useEffect(() => {
        // window.addEventListener('click', function(event) {
        //   if(event.target.localName === `a`) {
        //       console.log(`target is a `)
        //   }
        // })
        return () => {
        }
    }, [])
    const closeLoginMenu = useCallback((event) => {
        if(event.srcElement.localName === `a` && showLogin) {
            setShowLogin(!showLogin)
            console.log(`called`)
        }
    }, [setShowLogin, showLogin])

    useEffect(() => {
        if(showLogin) {
            window.addEventListener('click', closeLoginMenu)
        } 
        return () => window.removeEventListener('click', closeLoginMenu)
    }, [showLogin, closeLoginMenu])
    return (
        <div className="login-form-wrapper">
            <div className="login-form">
            <div className="form-header"> 
            <div></div>
                <div>RPS CORP</div>
                <div><MdClose onClick={closeLogin}/></div>
            </div>
            <div className="form-title">
                <h3>THE PARTS YOU NEED IN ONE PLACE</h3>
            </div>
            <div className="form-body">
                <input 
                placeholder='Email Adress'
                type="email"
                />
                <input 
                placeholder='Password'
                type="password" />
            </div>
                <div className="form-options">
                    <div>
                        <input type="checkbox" />
                        <label>Keep me signed in</label>
                    </div>
                    <div>
                        <Link to="">Forgot Password?</Link>
                    </div>
                </div>
                <div className="form-footer">
                    <p>By logging in, you agree to RPS's <Link to="/privacy">Privacy Policy</Link> and <Link to="/terms">Terms of Use</Link></p>
                    <button>SIGN IN</button>
                    <button onClick={googleSignin} disabled={disabled}>SIGN IN WITH GOOGLE <FcGoogle /></button>
                    <p>Not a member? <Link to="/register" onClick={closeLogin}>Sign Up</Link></p>
                </div>
            </div>
        </div>
    )
}
