import React from 'react'
import { useAuth } from '../AuthContext'
import Layout from '../Components/Layout'

export default function AccountSettings() {
    const { currentUser } = useAuth()
    console.log(currentUser)
    return (
        <Layout>
            <div className="padded-wrapper">
              <h1>Account Settings</h1>
              <button>Test</button>
            </div>
        </Layout>
    )
}
