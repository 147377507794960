import React, { useState, useEffect, useCallback } from 'react'
import './navigation.scss'
import { IoMdMenu, IoIosSearch, IoIosClose, IoMdLogIn, IoIosCheckmarkCircleOutline, IoIosSettings } from 'react-icons/io'
import {FaUser, FaSignOutAlt } from 'react-icons/fa'
import { GiMineTruck, GiVacuumCleaner } from 'react-icons/gi'
import { CgShoppingCart } from 'react-icons/cg'
import { MdOutlineHistory, MdCleaningServices } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../AuthContext'
import CartItem from './CartComponents/CartItem'
import logo from '../Images/favicon.svg';
 

export default function Navigation() {
    const [ showMenu, setShowMenu ] = useState(false)
    const [ showSearch, setShowSearch ] = useState(false)
    const [ showCart, setShowCart ] = useState(false)
    const [ showResulsts, setShowResults ] = useState(false)
    const [ searched, setSearched ] = useState('')
    const { showLogin,
         setShowLogin,
          currentUser, 
          logout, 
          cartCount,
          cartItems, 
          setCartItems,
          cartTotal } = useAuth()
    const  navigate = useNavigate();

    const toggleSearch = () => {
       if(showMenu || showCart) {
           setShowCart(false)
           setShowMenu(false)
           setShowSearch(!showSearch)
       } else {
           setShowSearch(!showSearch)
           setShowResults()
           setSearched('')
       }
    }
    const toggleMenu = () => {
        if(showCart) {
            setShowMenu(!showMenu)
            setShowCart(false)
        } else {
            setShowMenu(!showMenu)
        }
    }
    const toggleCart = () => {
        if(showMenu) {
            setShowMenu(false)
            setShowCart(!showCart)
        } else {
            setShowCart(!showCart)
        }
    }
    const toggleLogin = () => {
        setShowLogin(!showLogin)
        setShowMenu(false)
        console.log("showing login")
    }
    const searchResults = (event) => {
        let inputValue = event.target.value
        if(inputValue.length) {
            setShowResults(inputValue)
            setSearched(inputValue)
        }else {
            setShowResults()
        }
    }
    const toItemPage = (event) => {
        let id = event.target.parentElement.id
        navigate(`/parts/${id}`)
        setShowSearch(!showSearch)
    }
    const closeMenu = useCallback((event) => {
        if(event.srcElement.localName === `a` && showMenu) {
            setShowMenu(!showMenu)
            console.log(`called`)
        }
    }, [setShowMenu, showMenu])

    useEffect(() => {
        if(showMenu) {
            window.addEventListener('click', closeMenu)
        } 
        return () => window.removeEventListener('click', closeMenu)
    }, [showMenu, closeMenu])

    const buttonLink = (event) => {
        console.log(event)
    }
    return (
        <>
        {/* Mobile Navigation */}
        <nav className="mobile-nav">
            <div>
                <IoMdMenu onClick={toggleMenu} />
            </div>
            <div className="topRightNav">
            <IoIosSearch onClick={toggleSearch}/>
            <CgShoppingCart onClick={toggleCart}/>
            <span className="cart-counter">{cartCount === 0 ? "" : cartCount}</span>
            </div>
        </nav>
        {/* Desktop Navigation */}
        <nav className="desktop-nav">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.87 494.87"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" width="494.87" height="494.87"/><path d="M25.7,130H68.92q43.22,0,58.51,3.34t24.95,17q9.63,13.7,9.64,43.72,0,27.41-6.82,36.84t-26.82,11.31q18.12,4.5,24.36,12t7.76,13.85q1.51,6.31,1.52,34.74v61.92h-56.7v-78q0-18.85-3-23.35T86.76,259V364.83H25.7Zm61.06,40.17v52.21q10.29,0,14.42-2.83t4.14-18.34V188.34q0-11.16-4-14.65T86.76,170.21Z"/><path d="M185.66,130h61.49q24.93,0,38.35,3.92t20.16,11.31a38.84,38.84,0,0,1,9.14,17.91q2.38,10.51,2.39,32.56v20.44q0,22.48-4.64,32.78t-17,15.81q-12.39,5.5-32.41,5.51H246.71v94.55H185.66Zm61.05,40.17V230q2.61.15,4.5.15c5.6,0,9.5-1.38,11.67-4.14s3.26-8.48,3.26-17.18V189.5q0-12-3.77-15.66T246.71,170.21Z"/><path d="M464.1,201.1H407.39V183.7q0-12.18-2.17-15.52T398,164.85a9.22,9.22,0,0,0-8.34,4.5q-2.83,4.5-2.83,13.64,0,11.74,3.18,17.7,3,6,17.25,14.36,40.77,24.19,51.35,39.71t10.59,50q0,25.09-5.87,37t-22.7,19.94q-16.82,8.06-39.15,8.05-24.51,0-41.84-9.28t-22.7-23.64q-5.36-14.35-5.36-40.75V280.72h56.7v28.57q0,13.2,2.39,17t8.49,3.77q6.09,0,9.06-4.78t3-14.21q0-20.75-5.65-27.12-5.81-6.37-28.57-21.32t-30.17-21.9q-7.38-6.81-12.25-18.85t-4.86-30.74q0-27,6.89-39.45t22.26-19.5q15.38-7,37.13-7,23.78,0,40.53,7.69t22.19,19.36q5.43,11.67,5.44,39.66Z"/></g></g></svg>
            </div>
            <div>
                <ul>
                {/* IF signed in */}
                {/* <li><Link to="">Register Equipment</Link></li> */}
                <li><Link to="">Part Search</Link></li>
                <li><Link to="">Parts Manuals</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                </ul>
            </div>
        </nav>
        <div className="desktop-nav-two">
            {currentUser ?
                <ul>
                  <li><button onClick={buttonLink}><MdCleaningServices />My Equipment</button></li>
                  <li><button onClick={buttonLink}><IoIosSettings />Account Settings</button></li>
                  <li><button onClick={buttonLink}><MdOutlineHistory />Purchase History</button></li>
                  <li><button onClick={logout}><IoMdLogIn />LogOut</button></li>
                </ul>
                  :                 
                  <ul>
                    <li><button onClick={toggleLogin}><IoMdLogIn />Login</button></li>
                    <li><Link to=""><IoIosCheckmarkCircleOutline />Register</Link></li>
                 </ul>}
        </div>
        <div className={showSearch ? `search-menu show` : `search-menu hide`}>
            <div className="search-bar">
                <IoIosSearch className="open" /> 
                <input 
                onChange={searchResults} 
                value={searched}
                placeholder='Enter serial number...'
                />
                <IoIosClose className="close" onClick={toggleSearch} />
            </div>
            {showResulsts ? 
                    <div className="search-results">
                       <div className="searched">{showResulsts}</div>
                       <div className="result-items" id="Item One" onClick={toItemPage}>
                           <div className="item-image"></div>
                           <div className="item-name">Item One</div>
                           <div className="item-type">Tanks</div>
                           <div className="item-price">$5.00</div>
                       </div>
                       <div className="result-items" id="Item Two" onClick={toItemPage}>
                           <div className="item-image"></div>
                           <div className="item-name">Item Two</div>
                           <div className="item-type">Tanks</div>
                           <div className="item-price">$10.00</div>
                       </div>
                   </div>
            : ""}
 
        </div>
        <div className={showMenu ? "drop-down-menu show" : "drop-down-menu hide" }>
            <div>
                {currentUser ?
                  <button onClick={logout}><FaSignOutAlt />Sign Out</button> 
                  : <button onClick={toggleLogin}><FaUser /> Sign In</button>}
            </div>
            
                {currentUser ? 
                <ul>
                <li className="heading">Account</li> 
                <li><Link to="/account-settings">Account Settings</Link></li>
                <li><Link to="/purchase-history">Purchase History</Link></li>
                <li><Link to="/my-equipment">My Equipment</Link></li>
                </ul>
                : ""}
                <ul>
                    <li className="heading">General</li> 
                    <li><Link to="/contact">Contact Us</Link></li>
                </ul>
        </div>
        <div className={showCart ? "drop-down-menu show" : "drop-down-menu hide" }>
            <div className="cart">
                <h3>Cart</h3>
                <span className="overview">{cartItems.length} items | ${cartTotal}</span>
            </div>  
            <CartItem />
            <div className="summary">
                <h3>Summary</h3>
                <div className="totals subtotal">
                    <div>Subtotal</div>
                    <div>Price</div>
                </div>
                <div className="totals shipping">
                    <div>Estimated Shipping</div>
                    <div>Price</div>
                </div>
                <div className="totals tax">
                    <div>Tax</div>
                    <div>Price</div>
                </div>
                <div className="totals total">
                    <div>Total</div>
                    <div>Price</div>
                </div>
                {cartItems.length > 0 ? 
              <button className="checkout-btn">Checkout</button>
                : ""}
            </div>
        </div>
        </>
    )
}
