import React from 'react'
import { useAuth } from '../AuthContext'
import CartPopUp from './CartPopUp'
import Footer from './Footer'
import Login from './Login'
import Navigation from './Navigation'

export default function PaddedLayout({children}) {
    const { showLogin } = useAuth()
    return (
        <div className="wrapper">
            <Navigation />
            <CartPopUp />
            <div className={showLogin ? `form-wrapper show` : `form-wrapper hide`}>
                <Login /> 
            </div>
            <div className="padded-wrapper">
                {children}
            </div>
            <Footer />
        </div>
    )
}
