import React from 'react'
import { useAuth } from '../AuthContext'
import CartPopUp from './CartPopUp'
import Footer from './Footer'
import Login from './Login'
import Navigation from './Navigation'

export default function Layout({children}) {
    const { showLogin, showCartPopup } = useAuth()
    return (
        <div className="wrapper">
            <Navigation />
            <CartPopUp />
            <div className={showLogin ? `form-wrapper show` : `form-wrapper hide`}>
            <Login /> 
            </div>
            {children}
            <Footer />
        </div>
    )
}
