import React from 'react'
import PaddedLayout from '../Components/PaddedLayout'

export default function Privacy() {
    return (
        <PaddedLayout>
            <h1>Privacy Policy</h1>
        </PaddedLayout>
    )
}
