import React from 'react'
import PaddedLayout from '../Components/PaddedLayout'
import '../Styles/contact.scss';
export default function Contact() {
    return (
        <PaddedLayout>
            <h1>Contact Us</h1>
            <div className="contact-page">
                <h3>RPS Corportation</h3>
                <ul>
                    <li>1711 South Street</li>
                    <li>Racine WI, USA</li>
                </ul>
                <ul className="second">
                    <li>Toll Free: 1-800-123-1234</li>
                    <li>Phone: 800-123-1234</li>
                    <li>Fax: 1-800-123-1234</li>
                    <li>Email: someEmail@mail.com</li>
                </ul>
                <h3>Customer Care</h3>
                    <ul>
                        <li>Direct: 800-123-1234</li>
                        <li>Email: someEmail@mail.com</li>
                    </ul>
                <h3>Marketing</h3>
                    <ul>
                        <li>Email: someEmail@mail.com</li>
                    </ul>
                <h3>Sales</h3>
                    <ul>
                        <li>Email: someEmail@mail.com</li>
                    </ul>
                <h3>Human Resources</h3>
                    <ul>
                        <li>Email: someEmail@mail.com</li>
                    </ul>
            </div>
        </PaddedLayout>
    )
}
