import React from 'react'
import { createContext, useContext, useState } from 'react'
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithRedirect, signOut } from "firebase/auth";

const auth = getAuth();
const AuthContext = createContext()
const provider = new GoogleAuthProvider();

export function useAuth() {
    return useContext(AuthContext)
}
export default function AuthProvider({children}) {
  const auth = getAuth();
  const [ loading, setLoading ] = useState()
  const [ showLogin, setShowLogin ] = useState()
  const [ currentUser, setCurrentUser ] = useState(null)
  const [ cartCount, setCartCount ] = useState(0)
  const [ showCartPopup, setShowCartPopup ] = useState(false)
  const [ cartMessage, setCartMessage ] = useState('')
  const [ itemName, setItemName ] = useState('')
  const [ itemPrice, setItemPrice ] = useState(0.00.toFixed(2))
  const [ cartItems, setCartItems ] = useState([])
  const [ cartTotal, setCartTotal ] = useState(0.00.toFixed(2))

  const googleSignin = async () => {
    return signInWithRedirect(auth, provider);
  }
  const logout = async () => {
    return signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }
  const calculateCartTotal = () => {
    let total = 0
    cartItems.forEach((item) => {
      total = total + item.price
    })
    setCartTotal(total)
  }

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setCurrentUser(user)
      // ...
    } else {
      setCurrentUser(null)
    }
  })
  const value = {
      loading,
      setLoading,
      showLogin, 
      setShowLogin, 
      googleSignin, 
      logout, 
      currentUser,
      cartCount, 
      setCartCount, 
      setShowCartPopup, 
      showCartPopup,
      cartMessage, 
      setCartMessage, 
      setItemName, 
      itemName, 
      setItemPrice,
      itemPrice, 
      cartItems, 
      setCartItems,
      cartTotal,
      calculateCartTotal
    }
    return (
        <AuthContext.Provider value={value}>
          { !loading && children }
        </AuthContext.Provider>
    )
}
