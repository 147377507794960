import React from 'react'
import { useAuth } from '../AuthContext'
import './cartpopup.scss'
import { IoMdClose, IoIosCheckmark } from 'react-icons/io'

export default function CartPopUp(props) {
    const { showCartPopup, setShowCartPopup, cartMessage, itemName, itemPrice} = useAuth();

    if (showCartPopup) {
        return (
            <div className="cart-popup">
                <div className="alert-box">
                    <div className="message-box">
                       <IoIosCheckmark /> {itemName} has been added.<IoMdClose onClick={() => setShowCartPopup(false)}/>
                    </div>
                <button className="continue" onClick={() => setShowCartPopup(false)}>Continue Shopping</button>
                <button className="checkout">Check Out</button>
                </div>
            </div>
        )
    } else {
        return (
            ""
        )
    }
}
