
import React from 'react'
import { FcContacts } from 'react-icons/fc';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Register from './Pages/Register';
import AccountSettings from './Pages/AccountSettings';
import MyEquipment from './Pages/MyEquipment';
import PurchaseHistory from './Pages/PurchaseHistory';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import IndividualPart from './Pages/IndividualPart';
import Checkout from './Pages/Checkout';
import Updates from './Pages/Updates';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/my-equipment" element={<MyEquipment />} />
        <Route path="/purchase-history" element={<PurchaseHistory />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/parts/:id" element={<IndividualPart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/updates" element={<Updates />} />
      </Routes>
    </Router>
  );
}

export default App;

