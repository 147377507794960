import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer>
            <ul>
                <li><Link to="">Find a store</Link></li>
                <li><Link to="">Promotions</Link></li>
                <li><Link to="">Send us feedback</Link></li>
                <li><Link to="/updates">Site updates</Link></li>
                <li><Link to="">Get Help</Link></li>
                <li><Link to="">About RPS</Link></li>
                <li><Link to="">Privacy </Link></li>
                <li><Link to="">Terms of Use</Link></li>
            </ul>
            <div className="social">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </footer>
    )
}
