import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../AuthContext'
import PaddedLayout from '../Components/PaddedLayout'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'
import testImage from '../Images/favicon.png'

export default function IndividualPart(props) {
    const { id } = useParams()
    const [ quantity, setQuantity ] = useState(1)
    const { setCartCount, 
        cartCount, 
        setShowCartPopup,
         setCartMessage,
         setItemName,
         setItemPrice, 
         cartItems, 
         setCarItems,
         calculateCartTotal
        } =useAuth()
        const item = {
            name: id, 
            price: 20.00,
            quantity: quantity,
            category: `Test Parts`,
            image: testImage
        }
        const increaseQuantity = () => {
            let newQuantity
            if(quantity > 0 && quantity < 31) {
                newQuantity = quantity + 1
                setQuantity(newQuantity)
            } else {
                return
            }
        }
        const decreaseQuantity = () => {
            let newQuantity
            if(quantity > 1) {
                newQuantity = quantity - 1
                setQuantity(newQuantity)
            } else {
                return
            }
        }
        const addToCart = () => {
        console.log(`adding`)
        let i = cartCount
        i++
        setCartCount(i)
        console.log(`Added item to ${id} to cart`)
        setShowCartPopup(true)
        setItemName(id)
        setItemPrice(20.00)
        cartItems.push(item)
        calculateCartTotal()
    }
    return (
        <PaddedLayout>
            <div className="individual-parts-page">
                <h1>{id}</h1>
                <p>Fetch item from id param</p>
                <div className="image">
                    <img src={testImage} /> 
                </div>
                <div className="image-thumbnails">
                <div className="thumbs"></div>
                <div className="thumbs"></div>
                </div>
                <div className="quantity">
                    <div onClick={decreaseQuantity}><AiOutlineMinus/></div>
                    <div className="quanitityDisplay">Qty: {quantity}</div>
                    <div onClick={increaseQuantity}><AiOutlinePlus /></div>
                </div>
                <button onClick={addToCart}>Add to Cart</button>
                <div className="part-description">
                    <p>Voluptate consequat dolore laborum exercitation magna pariatur 
                       exercitation qui ut ex ad. In cupidatat commodo commodo exercitation.
                       Voluptate labore ex Lorem consectetur do mollit fugiat aute 
                       voluptate fugiat consectetur id esse. Voluptate tempor est aute
                       nisi consequat. </p>
                </div>
                <button className="view-details">View Product Details</button>
            </div>
        </PaddedLayout>
            
    )
}
