import React from 'react'
import Layout from '../Components/Layout'

export default function Register() {
    return (
        <Layout>
            <div className="padded-wrapper">
                 <h1>Register</h1>
            </div>
        </Layout>
    )
}
